import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box, Chip } from '@mui/material';

export default function DoctorBookingForm2() {
    // State variable for booking date
    const [bookingDate, setBookingDate] = React.useState(null);

    React.useEffect(() => {
        // Retrieve data from localStorage
        const formDataString = localStorage.getItem('formData');
        if (formDataString) {
            const formData = JSON.parse(formDataString);
            // Set the booking date from the stored data
            setBookingDate(formData.bookingDate);
        }
    }, []); // Run this effect only once, on component mount

    // Function to update localStorage with the new booking date
    const updateBookingDate = (newBookingDate) => {
        const formDataString = localStorage.getItem('formData');
        if (formDataString) {
            const formData = JSON.parse(formDataString);
            // Update the booking date in the formData object
            formData.bookingDate = newBookingDate;
            // Store the updated formData object in localStorage
            localStorage.setItem('formData', JSON.stringify(formData));
        }
    };

    return (
        <div>
            <Box sx={{ boxShadow: 2, width: '95%', borderRadius: '10px', margin: 'auto' }}><br />
                <h3 style={{ paddingLeft: '14%', marginTop: '0px' }}>Time Slot</h3>
                <center>
                    <Chip label="First Slot (10:00 AM - 2:30 PM)" color="primary" variant="outlined" style={{ width: '80%', height: '40px' }} />
                    <br /><br />
                    <Chip label="Second Slot (4:30 PM - 7:00 PM)" color="primary" variant="outlined" style={{ width: '80%', height: '40px' }} />
                </center>
<br /><hr />
                <h3 style={{ paddingLeft: '14%' }}>Booking Date</h3>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        style={{ border: '1px solid grey', borderRadius: '10px' }}
                        // Pass the booking date and the update function as props
                        value={bookingDate}
                        onChange={(date) => {
                            setBookingDate(date);
                            updateBookingDate(date);
                        }}
                    />
                </LocalizationProvider>
                <br /><br />
            </Box>
        </div>
    );
}
