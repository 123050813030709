import React from 'react'
import { useNavigate } from 'react-router-dom';

function ResultBlock() {
    const navigate = useNavigate()

    const handleNavigate = (e) => {
        e.preventDefault();
        navigate("/profile")
      }

    return (
        <div onClick={e => handleNavigate(e)} style={{ marginLeft: 'auto', marginRight: 'auto', height: '130px', width: '90%', backgroundColor: 'whitesmoke', borderRadius: '10px', border: '1px solid grey', display: 'flex', flexDirection: 'row' }}>
            <img src="https://www.charakhospital.org/docprofile/XDzGOT4QsZ.jpg" alt="" style={{ height: '110px', width: '110px', borderRadius: '10px', marginTop: '10px', marginLeft: '10px' }} />
            <div style={{ marginLeft: '20px' }}>
                <h3>Dr. Bhaskar Paul</h3>
                <ul style={{ marginLeft: '-20px'}}>
                    <li style={{ color: 'blue'}}><h4 style={{ marginTop: '-15px', color: '#352f44' }}>MBBS, MS (OTH) Cal</h4></li>
                    <li style={{ color: 'blue'}}><h4 style={{ marginTop: '-17px', color: '#352f44' }}>3 Years Experience</h4></li>
                    <li style={{ color: 'blue'}}><h4 style={{ marginTop: '-17px', color: '#352f44' }}>Fees: &#8377; 300</h4></li>
                </ul>
            </div>
        </div>
    )
}

export default ResultBlock