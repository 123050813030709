import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home.js";
import Header from "./components/Header.js";
import SearchDoctor from "./pages/SearchDoctor.js";
import Welcome from "./pages/Welcome.js";
import SendOTP from "./pages/SendOTP.js";
import IssueTrack from "./pages/IssueTrack.js";
import DoctorBooking from "./pages/DoctorBooking.js";
import EmergencyPage from "./pages/EmergencyPage.js";
import DoctorProfile from "./pages/DoctorProfile.js";
import SearchResult from "./pages/SearchResult.js";
import VerifyOTP from "./pages/VerifyOTP.js";
import AppointmentDetails from "./pages/AppointmentDetails.js";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sendotp" element={<SendOTP />} />
        <Route path="/verifyotp" element={<VerifyOTP />} />
        <Route path="/issuetrack" element={<IssueTrack />} />
        <Route path="/booking" element={<DoctorBooking />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/searchdoctor" element={<SearchDoctor />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/ep" element={<EmergencyPage />} />
        <Route path="/result" element={<SearchResult />} />
        <Route path="/profile" element={<DoctorProfile />} />
        <Route path="/appointment" element={<AppointmentDetails />} />
      </Routes>
    </div>
  );
}

export default App;
