// IssueTrackerPage.js
import React, { useState } from 'react';
import {
  Button,
  Container,
  Modal,
  Typography,
  TextField,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
} from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './IssueTrack.css';

const IssueTrackerPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [issuesDb, setIssuesDb] = useState([]);

  const handleRaiseIssue = () => {
    setModalOpen(true);
  };

  const handleSubmitIssue = () => {
    if (selectedCategory && issueDescription) {
      const newIssueId = generateIssueId();
      const newIssue = {
        id: newIssueId,
        category: selectedCategory,
        description: issueDescription,
        status: 'Issue Raised',
      };
      setIssuesDb([...issuesDb, newIssue]);
      setModalOpen(false);
      setSelectedCategory('');
      setIssueDescription('');
    }
  };

  const generateIssueId = () => {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    const firstDashIndex = uuid.indexOf('-');
    return firstDashIndex !== -1 ? uuid.substring(0, firstDashIndex) : uuid;
  };

  const handleAccordionClick = (issueId) => {
    // Handle accordion expansion/collapse
    console.log('Accordion clicked for issue:', issueId);
  };

  return (
    <Container className="it__issue-tracker-container">
      {!issuesDb.length ? (
        <div className="it__welcome-container">
          <Typography variant="h4">Welcome to Teledoc Issue Tracker</Typography>
          <Typography paragraph>
            If you have any issues or queries, feel free to raise them, and we are here to help!
          </Typography>
          <Button variant="contained" onClick={handleRaiseIssue} className="it__raise-issue-button">
            Raise an Issue
          </Button>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <TextField id="outlined-basic" label="Search Issue" variant="outlined" style={{ width: '70%' }}/>
          <Button variant="contained" onClick={handleRaiseIssue} style={{ width: '27%', paddingLeft: '1px', paddingRight: '1px'}}>
            Raise Issue
          </Button>
          </div>
          <hr /><br /><br />
          {/* Display a list of already raised issues */}
          {issuesDb.map((issue) => (
            <Accordion
              key={issue.id}
              onChange={() => handleAccordionClick(issue.id)}
              className="it__raised-issue"
            >
              <AccordionSummary>
                <Typography variant="h6" style={{ marginLeft: '10px' }}>
                  {issue.description.split(' ').slice(0, 10).join(' ')}...<br />
                </Typography>
              </AccordionSummary>
              <hr />
              <AccordionDetails>
                <Typography>{issue.description}</Typography>
                <Badge color="success" variant="dot">
                </Badge>
                &nbsp;&nbsp;Status: {issue.status}
                <Typography>&nbsp;&nbsp;Issue ID: {issue.id.toUpperCase()}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}

        </div>
      )}

      {/* Modal for raising an issue */}
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <div className="it__modal-container">
          <Typography variant="h4" className="it__modal-title">
            Raise an Issue
          </Typography>
          <br />
          <br />
          <TextField
            select
            label="Category"
            variant="outlined"
            fullWidth
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="it__category-dropdown"
          >
            <MenuItem value="General Query">General Query</MenuItem>
            <MenuItem value="Technical Issue">Technical Issue</MenuItem>
          </TextField>
          <br />
          <br />

          <TextField
            label="Describe your issue"
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            className="it__issue-description-input"
          />
          <br />
          <br />
          <Typography style={{ color: 'grey', marginTop: '50px' }}>
            *Your estimated response time is 2 day(s).
          </Typography>
          <br />
          <Button
            variant="contained"
            onClick={handleSubmitIssue}
            className="it__submit-issue-button"
            style={{ width: '90%' }}
          >
            Raise
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default IssueTrackerPage;
