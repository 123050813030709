import React, { useEffect } from 'react';

function DoctorBookingForm4() {
  window.myorderid = '';

  // useEffect(() => {
  //   const initializeRazorpay = async () => {
  //     try {
  //       const response = await fetch('https://api.razorpay.com/v1/orders', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           amount: 10,
  //           currency: 'INR',
  //           receipt: 'receipt#1',
  //           partial_payment: false,
  //           notes: {
  //             key1: 'value3',
  //             key2: 'value2',
  //           },
  //         }),
  //       });

  //       const order = await response.json();
  //       window.myorderid = order.id;

  //       // Load Razorpay script dynamically
  //       const script = document.createElement('script');
  //       script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  //       document.head.appendChild(script);

  //       script.onload = () => {
  //         const options = {
  //           key: 'YOUR_RAZORPAY_KEY',
  //           amount: '1000',
  //           currency: 'INR',
  //           name: 'Dr. Bhaskar Paul',
  //           description: 'Booking via Teledoc',
  //           image: '/assets/logo100.png',
  //           order_id: String(window.myorderid),
  //           callback_url: 'http://localhost:3000/paymentsucc',
  //         };

  //         const rzp1 = new window.Razorpay(options);
  //         document.getElementById('rzp-button1').onclick = function (e) {
  //           rzp1.open();
  //           e.preventDefault();
  //         };
  //       };
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };

  //   initializeRazorpay();
  // }, []);

  return (
    <div>
      <button id="rzp-button1">Pay</button>
    </div>
  );
}

export default DoctorBookingForm4;
