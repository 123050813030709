import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import './SignupDiv.css'
import { Button } from '@mui/material';
function OTPVerify() {
    const [otp, setOtp] = useState('');

    const verify = (e) => {
        e.preventDefault();
        localStorage.setItem("loginbuttonclick", true)
    }
    console.log("Yyyyyyyyy")
    return (
        <div>
            <div style={{ backgroundColor: 'lightyellow', height: '40vh' }}></div>
            <div style={{ background: 'linear-gradient(#FFF0D3, #FFC869)', border: '1px solid grey', width: '100vw', marginTop: '-50px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', height: '60vh' }}>
                <br /><br /><br />
                <div >
                    <OtpInput className="otp__input" inputStyle={{ width: '40px', height: '40px', borderRadius: '10px', border: '1px solid grey' }} containerStyle={{ justifyContent: 'space-between', paddingLeft: '30px', paddingRight: '30px' }} value={otp} onChange={setOtp} numInputs={6} renderInput={(props) => <input {...props} />} />
                </div>
                <center>
                    <Button variant="contained" style={{ marginTop: '40px', width: '90%', backgroundColor: '#FFA133', border: 'none', borderRadius: '10px', height: '50px', fontSize: '16px' }} onClick={e => verify(e)}>Verify</Button>
                </center>

            </div>
        </div>
    )
}

export default OTPVerify