import React from 'react'
import './Welcome.css'
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Welcome() {
  const navigate = useNavigate()

  const handleNavigate = (e, mytype) => {
    e.preventDefault();
    localStorage.setItem("onboardtype", mytype=="login" ? true : false)
    navigate("/login")
  }

  return (
    <div className='welcome'>
      <div className='welcome__topdiv'>

      </div>
      <center>
        <div className='welcome__downdiv'>
          <Stack spacing={2} direction="column">
            <Button variant="contained" style={{ backgroundColor: '#FFA133', border: 'none', borderRadius: '10px', height: '50px', fontSize: '16px'}} onClick={e => handleNavigate(e, 'login')}>Login</Button>
            <div className='welcome__separate'>
              <span className='welcome__span'>
                OR
              </span>
            </div><br />
            <Button variant="outlined" style={{ border: '2px solid #FFA133', color: 'black', borderRadius: '10px', height: '50px', fontSize: '16px'}}>Sign up</Button>
          </Stack>
        </div>
      </center>
    </div>
  )
}

export default Welcome