import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box, MenuItem, Select, TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function DoctorBookingForm1() {
    const [bookingfor, setBookingfor] = React.useState('');
    const [name, setName] = React.useState('');
    const [dob, setDob] = React.useState(null); // Assuming you are using date for DOB
    const [sex, setSex] = React.useState('none');
    const [alternateMobile, setAlternateMobile] = React.useState('');

    React.useEffect(() => {
        if (bookingfor === 'self') {
            console.log('self');
        } else {
            console.log('other');
        }
    }, [bookingfor]);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            // Store the values in localStorage as an object
            const formData = {
                bookingfor,
                name,
                dob: dob?.toISOString(), // Convert Date object to ISO string
                sex,
                alternateMobile,
            };
            localStorage.setItem('formData', JSON.stringify(formData));
        }, 500);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount

    }, [bookingfor, name, dob, sex, alternateMobile]);

    return (
        <div>
            <Box sx={{ boxShadow: 2, width: '95%', borderRadius: '10px', margin: 'auto' }}>
                <FormControl sx={{ padding: '20px' }}>
                    <FormLabel id="demo-radio-buttons-group-label">Booking For:</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={bookingfor}
                        defaultChecked="self"
                        onChange={(e) => setBookingfor(e.target.value)}
                    >
                        <FormControlLabel value="Self" control={<Radio />} label="Myself" />
                        <FormControlLabel value="Other" control={<Radio />} label="Others" />
                    </RadioGroup>
                </FormControl>
                <hr />
                <br />
                <Box sx={{ boxShadow: 3, width: '90vw', margin: 'auto' }}>
                    <FormControl>
                        <br />
                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            style={{ width: '80vw', marginLeft: '6%' }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{ width: '80vw', marginLeft: '6%' }}>
                                <DatePicker
                                    label="Date of Birth (DOB)"
                                    value={dob}
                                    onChange={(date) => setDob(date)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <br />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sex}
                            label="Sex"
                            onChange={(e) => setSex(e.target.value)}
                            style={{ width: '80vw', marginLeft: '6%' }}
                        >
                            <MenuItem value={'none'}>Select Sex</MenuItem>
                            <MenuItem value={'Male'}>Male</MenuItem>
                            <MenuItem value={'Female'}>Female</MenuItem>
                        </Select>
                        <br />
                        <TextField
                            id="outlined-basic"
                            label="Alternate Mobile No. (Optional)"
                            variant="outlined"
                            style={{ width: '80vw', marginLeft: '6%' }}
                            value={alternateMobile}
                            onChange={(e) => setAlternateMobile(e.target.value)}
                        />
                        <br />
                        <br />
                    </FormControl>
                </Box>
                <br />
                <br />
            </Box>
        </div>
    );
}

export default DoctorBookingForm1;
