import React from 'react'
import ResultBlock from '../components/ResultBlock'

function SearchResult() {
  return (
    <div>
      <br />
      <ResultBlock />
      <br />
      <ResultBlock />
      <br />
      <ResultBlock />
      <br />
      <ResultBlock />
      <br />
      <ResultBlock />
      <br />
      <ResultBlock />
    </div>
  )
}

export default SearchResult