import React from 'react'
import './SearchDoctor.css'
import { Button, TextField } from '@mui/material'
// import LoginIcon from '@mui/icons-material/Login';

function SearchDoctor() {
  return (
    <div className='sd'>

      <div className='sd__topbar'>
        <center>
          <h2 className='sd__toptext'>
            <span>- Search&nbsp;</span>
            <span>with&nbsp;</span>
            <span>Name -</span>
          </h2>

          <TextField id="outlined-required" label="Doctor's Name" style={{ marginTop: '-10px', width: '80vw' }} />
        </center>
      </div>

      <div className='sd__options'>
        <center>
          <u><h2 className='sd__toptext' style={{ marginTop: '10px' }}>
            <span>- Search&nbsp;</span>
            <span>with&nbsp;</span>
            <span>Specialization -</span>
          </h2></u>
        </center>
        <br />

        <div className="sd__option__row">
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 1</p>
          </div>
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 2</p>
          </div>
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 3</p>
          </div>
        </div>
        <br /><br />

        <div className="sd__option__row">
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 4</p>
          </div>
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 5</p>
          </div>
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 6</p>
          </div>
        </div>
        <br /><br />

        <div className="sd__option__row">
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 7</p>
          </div>
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 8</p>
          </div>
          <div className="sd__option__col">
            <div className="sd__option" />
            <p className="sd__option__text">Option 9</p>
          </div>
        </div>
        <br /><br />
        <hr />
        <center><p className='sd__footer'>Apptech - Copyright &#169; 2024</p></center>
      </div>

    </div>
  )
}

export default SearchDoctor