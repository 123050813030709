import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const DoctorProfile = () => {

    const navigate = useNavigate()

    const handleNavigate = (e) => {
        e.preventDefault();
        navigate("/booking")
    }

    return (
        <div>
            <div style={{ height: '120px', width: '100%', backgroundColor: 'whitesmoke', marginTop: '-10px' }}>
                <center><img src="https://www.charakhospital.org/docprofile/XDzGOT4QsZ.jpg" alt="" style={{ height: '110px', width: '110px', borderRadius: '10px', marginTop: '50px', border: '4px solid grey', }} /></center>
            </div>
            <div style={{ height: '80vh', width: '100%', backgroundColor: '#FFE5CA', border: '1px solid grey', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '-10px' }}>
                <center>
                    <h2 style={{ marginTop: '80px' }}>Dr. Bhaskar Paul</h2>
                    <h3 style={{ color: 'gray', marginTop: '-10px' }}>MBBS, MS - Ortho (Cal)</h3>
                    <h3 style={{ color: 'gray', marginTop: '-10px' }}>3 Years Experience</h3>
                </center>
                <hr /><br />

                <div style={{ border: '1px solid grey', borderRadius: '10px', backgroundColor: 'whitesmoke', marginLeft: 'auto', marginRight: 'auto', width: '95%' }}>
                    <div style={{ height: '60px', backgroundColor: 'rgba(255, 165, 0, 0.6)', border: '1px solid grey', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                        <h2 style={{ marginLeft: '20px', marginTop: '10px' }}>Book Appointment at Clinic</h2>
                    </div><br />
                    <div style={{ backgroundColor: 'white', width: '95%', height: '160px', border: '1px solid grey', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto' }} >
                        <h3 style={{ marginLeft: '20px' }}>Kalawati Medical (Kalimpong)</h3>
                        <h3 style={{ marginLeft: '20px', marginTop: '-20px', fontWeight: 500 }}>Consultation fee: &#8377;300</h3>
                        <h3 style={{ marginLeft: '20px', marginTop: '-20px', color: '#00b300', fontWeight: 500 }}>Slot Available: Tomorrow </h3>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px' }}>
                            <Button variant="outlined">Clinic Details</Button>
                            <Button variant="contained" onClick={e => handleNavigate(e)}>Book Appointment</Button>
                        </div>
                    </div><br />
                    <div style={{ backgroundColor: 'white', width: '95%', height: '160px', border: '1px solid grey', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <h3 style={{ marginLeft: '20px' }}>Kalawati Medical (Kalimpong)</h3>
                        <h3 style={{ marginLeft: '20px', marginTop: '-20px', fontWeight: 500 }}>Consultation fee: &#8377;300</h3>
                        <h3 style={{ marginLeft: '20px', marginTop: '-20px', color: '#00b300', fontWeight: 500 }}>Slot Available: Tomorrow </h3>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px' }}>
                            <Button variant="outlined">Clinic Details</Button>
                            <Button variant="contained" onClick={e => handleNavigate(e)}>Book Appointment</Button>
                        </div>
                    </div><br />
                    <div style={{ backgroundColor: 'white', width: '95%', height: '160px', border: '1px solid grey', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <h3 style={{ marginLeft: '20px' }}>Kalawati Medical (Kalimpong)</h3>
                        <h3 style={{ marginLeft: '20px', marginTop: '-20px', fontWeight: 500 }}>Consultation fee: &#8377;300</h3>
                        <h3 style={{ marginLeft: '20px', marginTop: '-20px', color: '#00b300', fontWeight: 500 }}>Slot Available: Tomorrow </h3>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px' }}>
                            <Button variant="outlined">Clinic Details</Button>
                            <Button variant="contained" onClick={e => handleNavigate(e)}>Book Appointment</Button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
};

export default DoctorProfile;
