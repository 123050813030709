import { Box, Button, Divider } from '@mui/material'
import React from 'react'

function AppointmentDetails() {
    return (
        <div>
            <div style={{ height: '140px', width: '100%', backgroundColor: 'whitesmoke', marginTop: '-20px' }}>
                <center><br />
                    <h2 style={{ marginTop: '0px' }}>Appointment Progress</h2>
                    <img src="https://www.charakhospital.org/docprofile/XDzGOT4QsZ.jpg" alt="" style={{ height: '110px', width: '110px', borderRadius: '10px', marginTop: '-10px', border: '4px solid grey', }} />
                </center>
            </div>
            <div style={{ height: '100vh', width: '100%', backgroundColor: '#FFE5CA', borderTop: '1px solid grey', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '-20px' }}>
                <center>
                    <h2 style={{ marginTop: '70px' }}>Dr. Bhaskar Paul</h2>
                    <Button variant="outlined" style={{ marginTop: '-10px', width: '180px' }}>Visit Profile</Button>

                    <h3>Booked For: 21/02/2023 (7:30PM)</h3>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '30%' }}>
                        <span style={{ marginTop: '-2px', width: '5px', height: '5px', display: 'inline-block', borderTop: '1px solid green', backgroundColor: 'green', borderRadius: '100%', }}></span>&nbsp;&nbsp;
                        <p style={{ color: 'green', marginTop: '-10px' }}>Started at 6:03PM</p>&nbsp;&nbsp;
                        <span style={{ marginTop: '-2px', width: '5px', height: '5px', display: 'inline-block', borderTop: '1px solid green', backgroundColor: 'green', borderRadius: '100%', }}></span>
                    </div>

                    <hr />
                </center>
                <div>


                    <Box sx={{ display: 'flex', alignItems: 'center', borderColor: 'divider', marginTop: '-10px', justifyContent: 'space-between', paddingLeft: '30px', paddingRight: '30px' }}>
                        <Button variant="outlined" style={{ width: '120px', marginTop: '10px' }} color="secondary">I'll be late</Button>
                        <Divider orientation="vertical" flexItem style={{ marginTop: '10px' }} />
                        <Button variant="outlined" style={{ width: '170px', marginTop: '10px' }} color="error">Cancel Booking</Button>
                    </Box>
                    <hr style={{ marginTop: '10px' }} />
                    <br />

                    <div style={{ width: '95%', height: '500px', backgroundColor: 'whitesmoke', marginLeft: 'auto', marginRight: 'auto', border: '1px solid grey', borderRadius: '20px' }}>
                            <center><u><h2>Waiting List</h2></u></center>
                        <div style={{ maxHeight: '450px', overflow: 'scroll' }}>
                            <div style={{ width: '100%', height: '60px', backgroundColor: '#D3F1A7', border: '1px solid darkgrey', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Aarav Sharma</h3>
                                <h3 style={{ paddingRight: '20px'}}>Ongoing</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Diya Patel</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Rohan Singhania</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', backgroundColor: '#EFFFD6', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Spandan Manna</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Arjun Kapoor</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Meera Reddy</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Aditya Verma</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Ananya Choudhary</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Vikram Joshi</h3>
                            </div>
                            <div style={{ width: '100%', height: '60px', borderTop: '1px solid darkgrey' }}>
                                <h3 style={{ paddingLeft: '20px'}}>Priya Khanna</h3>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default AppointmentDetails