import React from 'react'
import './Home.css'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// import LoginIcon from '@mui/icons-material/Login';

function Home() {
  const navigate = useNavigate()

  const handleNavigate = (e, url) => {
    e.preventDefault();
    navigate(url)
  }
  return (
    <div className='home'>

      <div className='home__topbar'>
        <h1 className='home__toptext'>
          <span>Welcome&nbsp;</span>
          <span>Guest</span>
        </h1>
        {/* <Button variant="contained" style={{ backgroundColor: '#FFA133', color: 'white', height: '50px', marginTop: '15px' }} onClick={e => handleNavigate(e)}>Login</Button> */}
        <div></div>
      </div>

      <div className='home__options'>
        <center>
          <u><h1 className='home__toptext' style={{ marginTop: '10px' }}>
            <span> Dashboard</span>
          </h1></u>
        </center>
        <br /><br />

        <div className="home__option__row">
          <div className="home__option__col">
            <div className="home__option">
              <center>
                <img src="/assets/call.png" onClick={(e) => { handleNavigate(e, "/ep") }} style={{ width: '70%', height: '70%', marginTop: '10px' }} alt="" />
              </center>
            </div>
            <p className="home__option__text" style={{ marginLeft: '10px' }}>Emergency</p>
          </div>
          <div className="home__option__col">
            <div className="home__option">
              <center>
                <img src="/assets/SearchDoc.png" onClick={(e) => { handleNavigate(e, "/ep") }} style={{ width: '90%', height: '90%', marginTop: '5px' }} alt="" />
              </center>
            </div>
            <p className="home__option__text">Doctors</p>
          </div>
          <div className="home__option__col">
            <div className="home__option">
              <center>
                <img src="/assets/Appointments.png" onClick={(e) => { handleNavigate(e, "/ep") }} style={{ width: '90%', height: '90%', marginTop: '5px' }} alt="" />
              </center>
            </div>
            <p className="home__option__text">Appointments</p>
          </div>
        </div>
        <br /><br />

        {/* <div className="home__option__row">
          <div className="home__option__col">
            <div className="home__option">
              <center>
                <img src="/assets/Prescription.png" onClick={(e) => { handleNavigate(e, "/ep") }} style={{ width: '90%', height: '90%', marginTop: '5px' }} alt="" />
              </center>
            </div>
            <p className="home__option__text">Option 4</p>
          </div>
          <div className="home__option__col">
            <div className="home__option" />
            <p className="home__option__text">Option 5</p>
          </div>
          <div className="home__option__col">
            <div className="home__option" />
            <p className="home__option__text">Option 6</p>
          </div>
        </div>
        <br /><br />

        <div className="home__option__row">
          <div className="home__option__col">
            <div className="home__option" />
            <p className="home__option__text">Option 7</p>
          </div>
          <div className="home__option__col">
            <div className="home__option" />
            <p className="home__option__text">Option 8</p>
          </div>
          <div className="home__option__col">
            <div className="home__option" />
            <p className="home__option__text">Option 9</p>
          </div>
        </div>
        <br /><br /> */}
        <hr />
        <center><p className='home__footer'>Apptech - Copyright &#169; 2024</p></center>
      </div>

    </div>
  )
}

export default Home