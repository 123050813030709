// EmergencyPage.js
import React from 'react';
import { Container, Typography, Divider, Box, Paper } from '@mui/material';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PhoneIcon from '@mui/icons-material/Phone';

const EmergencyPage = () => {

  const emergencyOptions = [
    { name: 'Police', number: '100' },
    { name: 'Fire', number: '101' },
    { name: 'National Emergency', number: '112' },
    { name: 'Disaster Management', number: '108' },
    { name: 'Women Helpline (Domestic Abuse)', number: '181' },
    { name: 'Air Ambulance', number: '9540161344' },
    { name: 'Aids Helpline', number: '1097' },
    { name: 'Anti Poison (New Delhi)', number: '1066 or 011-1066' },
    { name: 'Disaster Management (N.D.M.A)', number: '1078, 01126701728' },
    { name: 'Earthquake/Flood/Disaster (N.D.R.F Headquaters)', number: 'NDRF HELPLINE NO: 011-24363260 9711077372' },
    { name: 'Deputy Commissioner Of Police – Missing Child And Women', number: '1094' },
    { name: 'Railway Enquiry', number: '139' },
    { name: 'Senior Citizen Helpline', number: '14567' },
    { name: 'Railway Accident Emergency Service', number: '1072' },
    { name: 'Road Accident Emergency Service', number: '1073' },
    { name: 'Road Accident Emergency Service On National Highway For Private Operators', number: '1033' },
    { name: 'ORBO Centre, AIIMS (For Donation Of Organ) Delhi', number: '1060' },
    { name: 'Kisan Call Centre', number: '18001801551' },
    { name: 'Relief Commissioner For Natural Calamities', number: '1070' },
    { name: 'Children In Difficult Situation', number: '1098' },
    { name: 'National Poisons Information Centre - AIIMS NEW DELHI (24*7)', number: '1800116117, 011-26593677, 26589391' },
    { name: 'Poison Information Centre (CMC, Vellore)', number: '18004251213' },
    { name: 'Tourist Helpline', number: '1363 or 1800111363' },
    { name: 'LPG Leak Helpline', number: '1906' },
    { name: 'KIRAN MENTAL HEALTH Helpline', number: '18005990019' },
    { name: 'CYBER CRIME HELPLINE', number: '155620' },
    { name: 'COVID 19 HELPLINE', number: '011-23978046 OR 1075' },
  ];


  return (
    <Container>
      <Typography variant="h4" align="center" mt={4} mb={2}>
        Emergency
      </Typography>


      {/* Ambulance Option */}
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Paper elevation={3} style={{ padding: '20px', width: '80%', height: '150px', border: '2px dashed red' }}>
          <center>
            <img src="/assets/ambulance.png" alt="" style={{ height: '120px' }} />
            <h1 style={{ marginTop: '-10px' }}>Ambulance</h1>
          </center>
        </Paper>
      </Box>

      {emergencyOptions.map((option, index) => (
        <React.Fragment key={index}>
          <Divider my={4} />
          <Box display="flex" alignItems="center" mb={2} style={{ marginTop: '10px', paddingLeft: '10px' }}>
            <Paper elevation={3} style={{ padding: '10px', marginRight: '20px' }}>
              {option.image ? (
                <img src={option.image} alt={option.name} style={{ height: '30px' }} />
              ) : (
                <PhoneIcon style={{ fontSize: 30, color: '#2196F3' }} />
              )}
            </Paper>
            <h2>{option.name}</h2>
            {/* : {option.number} */}
          </Box>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default EmergencyPage;
