import React from 'react'
import './Header.css'

function Header() {
  return (
    <div className='header'>
        <img src="/assets/logo100.png" className="header__logo" alt="" />
        <h1 className='header__text'>Teledoc</h1>
    </div>
  )
}

export default Header