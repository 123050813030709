import { Box } from '@mui/material';
import React from 'react';

const DoctorBookingForm3 = () => {
    // Retrieve data from localStorage
    const formDataString = localStorage.getItem('formData');
    const formData = formDataString ? JSON.parse(formDataString) : null;

    if (!formData) {
        return (
            <div>
                <h2>No data available</h2>
            </div>
        );
    }

    return (
        <div>
            <Box sx={{ boxShadow: 2, width: '90%', borderRadius: '10px', margin: 'auto', paddingLeft: '20px' }}><br />
                <h2>Review Booking Details</h2>
                <hr /><br />
                <p><strong>Booking For:</strong> {formData.bookingfor}</p>
                <p><strong>Name:</strong> {formData.name}</p>
                <p><strong>Date of Birth (DOB):</strong> {formData.dob}</p>
                <p><strong>Sex:</strong> {formData.sex}</p>
                <p><strong>Alternate Mobile No. (Optional):</strong> {formData.alternateMobile}</p>
                <p><strong>Booking Date:</strong> {formData.bookingDate}</p>
                <br /><br />
            </Box>
        </div>
    );
};

export default DoctorBookingForm3;
